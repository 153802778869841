export const API_USER_REGISTRATION = '/api/auth/register'
export const API_USER_LOGIN = '/api/auth/login'
export const API_USER_LOGOUT = '/api/auth/logout'
export const API_USER_GET = '/api/users/me'
export const API_USER_UPDATE = '/api/users/my-update'
export const API_USER_SMS_VERIFY = '/api/auth/send-verify-phone-sms'
export const API_USER_SMS_RESET_PASS = '/api/auth/reset-by-sms-code'
export const API_COUNTRY_CODE = '/api/auth/country-codes'

export const API_SUB_USER_SMS = '/api/users/check-parent-id'
export const API_SUB_USER_ADD = '/api/users/add-parent-to-user'

export const API_WASH_GET = '/api/washes'
export const API_WASH_BOXES_GET = '/api/washes/:washId/boxes'

export const API_CARDS = '/api/loyalty-cards'
export const API_USER_COUNT_CREATE = '/api/users/loyalty-card/create'
export const API_USER_COUNT_DELETE = '/api/users/loyalty-card/delete'

export const API_USER_GROUPS_GET = '/api/users/my-group'
export const API_USER_GROUP_CREATE = '/api/loyalty-group/create'
export const API_USER_GROUP_DELETE = '/api/loyalty-group/delete'
export const API_USER_GROUP_UPDATE = '/api/loyalty-group/update'
export const API_USER_GROUP_GET_SINGLE = '/api/users/my-group/'

export const API_USER_GROUP_INVITE = '/api/users/send-invitation'
export const API_USER_GROUP_RESPONSE = '/api/users/invitation-response'
export const API_USER_GROUP_GET_INVITE_RESPONSE = '/api/users/my-invitations'
export const API_USER_GROUP_MY_TRANSFERS = '/api/users/my-transfers'


export const API_VERIFY_CARD = '/api/loyalty-cards/verify/'
export const API_ACTIVATE_CARD = '/api/loyalty-cards/set-active/'
export const API_DELETE_CARD = '/api/loyalty-cards/'


export const API_USER_REPLENISHMENT = '/api/users/my-replenishment'
export const API_USER_TRANSACTION = '/api/users/my-transaction'
export const API_USER_PAY_OF_BALANCE = '/api/payments/payment-of-balance'
export const API_USER_TRANSFER_TO_USER = '/api/users/transfer-to-user'


export const API_BONUS = '/api/bonus'

export const API_PAY = '/api/payments/liqpay/cnb-form-raw'
export const API_PAY_DEPOSIT = '/api/payments/liqpay/deposit-account'



export const API_ORDER_GET = '/api/orders/show/'

export const API_PAY_CHECK_GET = '/api/get-pdf/'



