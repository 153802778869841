const token = () => {
    if(!localStorage.getItem('userToken')) {
        return null
    }

    let token;
    if(localStorage.getItem('userToken')) {
        token = localStorage.getItem('userToken').replaceAll('"', '')
    } 

    return token
}

export const liqpay = {

    state: () => ({
        payData: null,
        order: null,
        is_paid: null,
        errors: []
    }),
    
    actions: {
       async Pay({commit}, data) {
            let url = '/api/payments/liqpay/cnb-form-raw';

            if(data.balance) {
                url = '/api/payments/liqpay/deposit-account';
            }

            delete data.balance

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: JSON.stringify(data)
            }

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+url,
                    requestOptions
                    )
                    const pay = await res.json()

                    if(res.status !== 200) {
                        commit('setErrors', pay.message)
                        return;
                    }
               
                    commit('setPaymendForm', pay)

        },

        async loadOrder({commit}, orderdata) {

           const id = orderdata.orderId ? orderdata.orderId : ''

           const washId = orderdata.washId ? orderdata.washId : ''
            
           const urlencoded = new URLSearchParams();
            urlencoded.append("washId", washId);

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/x-www-form-urlencoded',
                },
                body: urlencoded
            }

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+'/api/orders/show/'+id,
                    requestOptions
                )
                const order = await res.json()

                commit('setOrder', order.data)
        },

        async createPaymentLiqpay({commit}, payData) {
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify(payData)
            }
               try {
                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+'/api/payments/liqpay/cnb-form-raw',
                    requestOptions
                    )
                    const pay = await res.json()
    
                    if(res.status !== 200) {
                        commit('setErrors', pay.message)
                        return;
                    }
    
                commit('setPayment', pay.data)                
               }
               catch (error) {
                commit('setErrors', error)
               }
        }
       
    },
    mutations: {
        
        setPaymendForm(state, paymend) {
           
            if(!paymend) {
                state.payData = null
                return
            }         
            state.payData = paymend
        },

        setOrder(state, order) {
           
            if(!order) {
                state.order = null
                return
            }         

            state.order = order
            state.is_paid = order.is_paid
           
        },

        setPayment(state, order) {
            if(!order) {
                state.order = null
                return
            }

            state.order = order
        },

        setErrors(state, error) {
            if(!error) {
                state.errors = []
                return;
            }
            state.errors = []
            state.errors.push(error)
        }
    },

    getters: {
        getPaymendForm( state ){
            return state.payData
        },

        getOrder( state ){
            return state.order
        },

        getIsPaid( state ) {
            return state.is_paid
        },
        getErrors( state ) {
            return state.errors
        }
    },
}