<template>
    <div class="pwa-bar ios-bar" v-if="deferredPrompt && this.iOS" :class="{' ios-bar' : iOS}">
        <div class="info">
          <div class="icons-wrap">
           <img src="../assets/images/logo.png"  width="100" height="40" alt="логотип сайту">
          </div>
          <p v-if="iOS">Встановіть цей сайт на Ваш iPhon: натисніть <img :src="require('/src/assets/images/iphone_Action.png')" width="19" height="20" alt="iPhon іконка меню"> і тоді додати На Початковий екран (homescreen)</p>
            <p v-else>Встановити ярлик сайту на головний екран</p>

        </div>
        <div>
          <button class="pwa-button btn" @click="pwaButton" v-if="!iOS" >Встановити</button>
        </div>
        <button class="close-pwa" @click="dismissPwaButton" name="close pwa bar">X</button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            deferredPrompt: false,
            iOS: false,
            showPWA: false,
        }
    },
    mounted() {
        this.isIos()
        
        this.showPWA = localStorage.getItem('hiddePWA') ?  true : false

        this.captureEvent()
        
    },
    methods: {
            captureEvent() {
            if(!this.iOS) {
                window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent Chrome 67 and earlier from automatically showing the prompt
                    e.preventDefault()
                    // Stash the event so it can be triggered later.
                    this.deferredPrompt = e
                    this.showPWA = false
                })
            } else {
                
                    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
                    // Checks if should display install popup notification:
                    if (!isInStandaloneMode() && !this.showPWA) {
                       
                        this.deferredPrompt = true;
                        
                    }
               }

               
            },

            isIos() {
                const userAgent = window.navigator.userAgent.toLowerCase();

                this.iOS = /iphone|ipad|ipod/.test( userAgent )

            },

            pwaButton() {
                // showPwaButton
                this.deferredPrompt.prompt()
                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                    // Add analyticcs event
                    this.$gtag.event('add_to_home_screen')
                }
                    this.deferredPrompt = false
                })
                },
            dismissPwaButton() {
                this.deferredPrompt = false;

                localStorage.setItem('hiddePWA', 'true');
            }
        },
}
</script>
<style lang="">
    
</style>