<template>
    <nav class="user-menu" v-if="getUser">
        <ul>
            <li>
                <router-link :to="{name: 'home'}">
                    <svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                            viewBox="0 0 27.02 27.02" xml:space="preserve">
                        <g>
                            <path style="fill:#030104;" d="M3.674,24.876c0,0-0.024,0.604,0.566,0.604c0.734,0,6.811-0.008,6.811-0.008l0.01-5.581
                                c0,0-0.096-0.92,0.797-0.92h2.826c1.056,0,0.991,0.92,0.991,0.92l-0.012,5.563c0,0,5.762,0,6.667,0
                                c0.749,0,0.715-0.752,0.715-0.752V14.413l-9.396-8.358l-9.975,8.358C3.674,14.413,3.674,24.876,3.674,24.876z"/>
                            <path style="fill:#030104;" d="M0,13.635c0,0,0.847,1.561,2.694,0l11.038-9.338l10.349,9.28c2.138,1.542,2.939,0,2.939,0
                                L13.732,1.54L0,13.635z"/>
                            <polygon style="fill:#030104;" points="23.83,4.275 21.168,4.275 21.179,7.503 23.83,9.752 	"/>
                        </g>
                        </svg>
                        <span>Головна</span>
                </router-link>
            </li>
            <!-- <li><router-link :to="{name: 'UserAccount'}">
                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Кабінет</span>
                </router-link></li> -->
            <li><router-link :to="{name: 'UserAccount'}">
                    <svg fill="#000000" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Zm8.99-5L19.415,9c-.008-.022-.036-.107-.046-.129l1.11-1.11a2.011,2.011,0,0,0,0-2.842l-1.4-1.4a2,2,0,0,0-1.421-.588h0a2,2,0,0,0-1.419.588L15.07,4.612,15,4.58V3.009A2.011,2.011,0,0,0,12.99,1H11.01A2.011,2.011,0,0,0,9,3.009L9,4.566l-.086.049-.043.016L7.765,3.522a2,2,0,0,0-1.42-.589h0a2,2,0,0,0-1.421.588l-1.4,1.4a2.011,2.011,0,0,0,0,2.842l1.1,1.143c-.013.029-.033.063-.043.093H3.01A2.011,2.011,0,0,0,1,11.009v1.982A2.011,2.011,0,0,0,3.01,15l1.575,0c.008.022.036.107.046.129l-1.11,1.11a2.011,2.011,0,0,0,0,2.842l1.4,1.4a2.059,2.059,0,0,0,2.842,0l1.115-1.115c.022.011.1.047.121.056v1.571A2.011,2.011,0,0,0,11.01,23h1.98A2.011,2.011,0,0,0,15,20.991l0-1.557.129-.065,1.109,1.109a2.058,2.058,0,0,0,2.843,0l1.4-1.4a2.011,2.011,0,0,0,0-2.842l-1.1-1.143c.013-.029.033-.063.043-.093H20.99A2.011,2.011,0,0,0,23,12.991V11.009A2.011,2.011,0,0,0,20.99,9Zm0,4H19.421a2.1,2.1,0,0,0-1.466,3.54l1.109,1.124-1.414,1.4-1.11-1.109A2.1,2.1,0,0,0,13,19.42L12.99,21,11,20.991V19.42a2.043,2.043,0,0,0-1.307-1.881,2.138,2.138,0,0,0-.816-.164,2,2,0,0,0-1.417.58L6.336,19.064l-1.4-1.414,1.108-1.108A2.1,2.1,0,0,0,4.579,13L3,12.991,3.01,11H4.579A2.1,2.1,0,0,0,6.045,7.46L4.936,6.336l1.414-1.4L7.46,6.045a2.04,2.04,0,0,0,2.227.419l.018-.007A2.04,2.04,0,0,0,11,4.58L11.01,3,13,3.009V4.58a2,2,0,0,0,1.227,1.845c.026.013.057.027.087.039a2.038,2.038,0,0,0,2.226-.419l1.124-1.109,1.4,1.414L17.956,7.458A2.1,2.1,0,0,0,19.421,11H20.99l.01.009Z"/></svg>
                    <span>Налаштування</span>
                </router-link></li>
            <li><router-link :to="{name: 'UserCards'}">
                    <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="ic_fluent_payment_24_regular" fill="#212121" fill-rule="nonzero">
                                    <path d="M19.2388849,5.0207074 C20.7014167,5.0207074 21.8972891,6.162412 21.9838661,7.60318507 L21.9888849,7.7707074 L21.9888849,16.275793 C21.9888849,17.7383249 20.8471803,18.9341973 19.4064072,19.0207742 L19.2388849,19.025793 L4.76104885,19.025793 C3.29851702,19.025793 2.10264457,17.8840884 2.01606765,16.4433154 L2.01104885,16.275793 L2.01104885,7.7707074 C2.01104885,6.30817556 3.15275345,5.11230312 4.59352652,5.02572619 L4.76104885,5.0207074 L19.2388849,5.0207074 Z M20.4880489,10.9947074 L3.51004885,10.9947074 L3.51104885,16.275793 C3.51104885,16.9230017 4.00292352,17.4553269 4.63324361,17.5193394 L4.76104885,17.525793 L19.2388849,17.525793 C19.8860935,17.525793 20.4184188,17.0339184 20.4824312,16.4035983 L20.4888849,16.275793 L20.4880489,10.9947074 Z M18.2529045,14.5 C18.6671181,14.5 19.0029045,14.8357864 19.0029045,15.25 C19.0029045,15.6296958 18.7207506,15.943491 18.3546751,15.9931534 L18.2529045,16 L15.7529045,16 C15.3386909,16 15.0029045,15.6642136 15.0029045,15.25 C15.0029045,14.8703042 15.2850584,14.556509 15.6511339,14.5068466 L15.7529045,14.5 L18.2529045,14.5 Z M19.2388849,6.5207074 L4.76104885,6.5207074 C4.11384016,6.5207074 3.58151495,7.01258206 3.51750246,7.64290216 L3.51104885,7.7707074 L3.51004885,9.4947074 L20.4880489,9.4947074 L20.4888849,7.7707074 C20.4888849,7.12349871 19.9970102,6.5911735 19.3666901,6.52716101 L19.2388849,6.5207074 Z" id="🎨-Color">
                        </path>
                                </g>
                            </g>
                    </svg>
                    <span>Картки</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{name: 'UserPayHistory'}">
                   <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M4068 4504 c-15 -8 -32 -23 -38 -34 -6 -10 -10 -90 -10 -184 l0 -166 -557 0 c-511 0 -561 -1 -593 -18 -53 -26 -60 -49 -60 -215 l0 -148 -47 8 c-90 15 -381 9 -460 -10 -431 -103 -759 -404 -891 -817 -17 -52 -36 -133 -42 -179 -18 -117 -8 -363 18 -461 22 -82 61 -189 86 -239 l17 -31 -196 0 -195 0 0 170 c0 167 -1 171 -24 195 -29 29 -66 40 -101 28 -32 -10 -953 -829 -967 -859 -6 -12 -8 -38 -4 -57 6 -31 62 -85 472 -449 255 -227 476 -419 491 -427 21 -10 34 -11 64 -2 65 19 69 34 69 223 l0 168 558 0 c510 0 560 1 592 18 53 26 60 49 60 215 l0 148 48 -8 c89 -15 380 -9 459 10 431 103 759 405 891 817 44 137 56 236 49 414 -6 168 -29 275 -91 419 l-34 77 194 0 194 0 0 -166 c0 -94 4 -174 10 -185 16 -29 68 -52 104 -45 23 4 156 117 503 425 259 231 474 429 478 441 4 12 4 37 1 55 -6 29 -70 90 -464 441 -251 224 -470 415 -487 425 -36 23 -61 23 -97 3z m542 -659 c136 -121 248 -224 248 -230 1 -5 -141 -137 -316 -292 l-317 -282 -5 105 c-5 104 -5 106 -38 135 l-32 29 -321 0 -322 0 -81 83 c-102 104 -193 172 -318 237 l-98 51 0 120 0 119 570 0 c629 0 607 -2 629 62 6 17 11 71 11 121 l0 89 72 -63 c39 -35 182 -163 318 -284z m-1775 -319 c107 -32 224 -89 309 -150 70 -50 198 -180 243 -247 51 -75 110 -199 139 -295 26 -83 28 -100 28 -274 0 -174 -2 -191 -28 -274 -54 -178 -141 -320 -272 -447 -126 -121 -249 -193 -419 -245 -85 -26 -101 -28 -275 -28 -174 0 -190 2 -275 28 -170 52 -293 124 -419 245 -132 128 -217 267 -272 446 -26 85 -28 101 -28 275 0 174 2 190 28 275 55 179 140 318 272 446 92 88 146 126 252 179 163 80 261 101 467 96 139 -3 172 -7 250 -30z m-1897 -1687 l32 -29 321 0 320 0 93 -93 c97 -97 210 -179 334 -241 l72 -36 0 -120 0 -120 -570 0 c-500 0 -574 -2 -595 -16 -33 -22 -38 -37 -44 -150 l-6 -101 -316 281 c-174 155 -317 285 -319 290 -1 5 141 136 316 291 l319 283 5 -105 c5 -103 6 -105 38 -134z"/>
                    <path d="M2507 3349 c-30 -18 -47 -65 -47 -133 l0 -65 -53 -24 c-184 -84 -247 -338 -119 -479 46 -51 125 -97 259 -149 133 -53 147 -62 167 -104 31 -65 10 -145 -54 -198 -61 -52 -201 -51 -293 2 -60 34 -85 38 -126 17 -45 -24 -62 -80 -39 -131 19 -41 133 -103 221 -119 l37 -7 0 -60 c0 -94 32 -139 100 -139 69 0 100 44 100 144 l0 65 53 26 c73 34 144 107 181 185 28 58 31 75 31 155 0 83 -2 95 -34 153 -54 99 -97 129 -300 208 -99 38 -159 77 -167 109 -10 41 4 90 37 121 28 27 36 29 102 29 61 0 79 -4 119 -27 74 -44 133 -36 164 24 37 72 -10 134 -138 183 l-48 18 0 64 c0 69 -17 116 -49 133 -25 13 -81 12 -104 -1z"/>
                    </g>
                    </svg>
                    <span>Транзакції</span>
                </router-link>
            </li>
             <li>
                <button v-if="getUser" @click.prevent="logOut">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#fff" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 471.2 471.2" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5    s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5    S235.019,444.2,227.619,444.2z"/>
                                <path d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5    s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8    C455.319,239.9,455.319,231.3,450.019,226.1z"/>
                            </g>
                        </g>
                    </svg>
                    <span>Вийти</span>
                </button>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    computed: {
        getUser(){
            return this.$store.getters.getUser;
        }
    },
    methods: {
        logOut(){

            this.$swal.fire({
              icon: 'warning',
              title: 'Вийти з кабінету',
              showCancelButton: true,
              confirmButtonText: "Вийти",
              cancelButtonText: "Ні",
              confirmButtonColor: '#fc2629'
            }).then((result) => {

            if (result.isConfirmed) {
                this.$store.dispatch('logOutUser', {
                    userToken: localStorage.getItem('userToken'),
                    userId: localStorage.getItem('userId')
                })
                .then(()=>{this.$router.push({name:'home'})})
            } 
            })
            

        }
    },
}
</script>
<style lang="scss" scoped>
    @import "src/assets/scss/user-menu.scss";
</style>
