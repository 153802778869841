//import router from '@/router'

import {
    API_USER_GROUPS_GET,
    API_USER_GROUP_CREATE,
    API_USER_GROUP_DELETE,
    API_USER_GROUP_GET_SINGLE,
    API_USER_GROUP_INVITE,
    API_USER_GROUP_RESPONSE,
   API_USER_GROUP_GET_INVITE_RESPONSE,
   API_USER_GROUP_MY_TRANSFERS
} from '../ApiUrl'

const token = () => {
    if(!localStorage.getItem('userToken')) {
        return null
    }

    let token;
    if(localStorage.getItem('userToken')) {
        token = localStorage.getItem('userToken').replaceAll('"', '')
    } 

    return token
}

export const loyaltyGroups = {
    state: () => ({
        groups: [],
        single: [],
        invites: null,
        errors: [],
        userTransfers: []
    }),

    actions: {
        async createGroup({commit}, data) {
            if(!data) return

            const formData = new FormData()
            data.id ? formData.append("adminUserId", data.id) : '';
            data.groupId ? formData.append("washesGroupId", data.groupId) : '';
            data.name ? formData.append("name", data.name) : '';
            data.loyaltyCardId ? formData.append("loyaltyCardId", data.loyaltyCardId) : ''


            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            } 

            try {
                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_CREATE,
                    requestOptions,
                )
                
                

                if(res.status != 204) {
                    const data = await res.json()
                    commit('setGroupError', data )
                    return
                }

                commit('setGroupError', null )

            } catch (error) {
                console.error(error)
            }
        },

        async loadGroupList({commit}) {
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                }
            } 

            try {
                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUPS_GET,
                    requestOptions
                )

                const data = await res.json()

                if(res.status != 200) {
                    commit('setGroupError', data.message )
                }
                    
                    commit('setGroupList', data.data)
                    commit('setGroupError', null )
            

            } catch (error) {
                console.error(error)
            }
        },

        async loadSingleGroup({commit}, id) {
            if(!id) return


            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                }
            } 

            try {
                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_GET_SINGLE+id,
                    requestOptions,
                )

                const data = await res.json()

                if(res.status != 200) {
                    commit('setGroupError', data.message )
                    return
                }
                    
                commit('setSingleGroup', data)
                commit('setGroupError', null )
                

            } catch (error) {
                console.error(error)
            }
        },

        async deleteGroup({commit}, id) {
            if(!id) return

            const formData = new URLSearchParams()
            id ? formData.append("id", id) : '';

            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            } 
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_DELETE,
                    requestOptions
                ) 

                if(res.status != 204) {

                    const data = res.json()

                    commit('setGroupError', data.message )
                }


                
            } catch (error) {
                console.error(error)
            }
        },

        async addUserToGoup({commit}, data) {
            if(!data) return

            const formData = new FormData()
            data.phone ? formData.append("phone",  data.phone) : '';
            data.id ? formData.append("groupId",  data.id) : '';

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            } 
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_INVITE,
                    requestOptions
                ) 

                if(res.status != 204) {
                    
                    const data = await res.json()
                    commit('setGroupError', data.message )
                }


                
            } catch (error) {
                console.error(error)
            }
            
        },

        async acceptInvite({commit}, data) {
            if(!data) return

            const formData = new FormData()
            data.isAccept ? formData.append("userResponse",  data.isAccept) : '';
            data.groupId ? formData.append("groupInvitationId",  data.groupId) : '';

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            } 
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_RESPONSE,
                    requestOptions
                ) 

                if(res.status != 204) {

                    const data = await res.json()

                    commit('setGroupError', data.message )
                }


                
            } catch (error) {
                console.error(error)
            }
            
        },

        async getAllUserInvites({commit}) {

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
            } 
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_GET_INVITE_RESPONSE,
                    requestOptions
                ) 
                const data = await res.json()

                if(res.status != 200) {

                    commit('setGroupError', data.message )
                }

                commit('setGroupInvites', data)

                
            } catch (error) {
                console.error(error)
            }
        },

        async loadUserGroupMoneyTransfers({commit}) {
            

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
            } 
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_GROUP_MY_TRANSFERS,
                    requestOptions
                ) 
                const data = await res.json()

                if(res.status != 200) {

                    commit('setGroupError', data.message )
                }

                commit('setUserGroupTransfers', data)

                
            } catch (error) {
                console.error(error)
            }
        }
    },

    mutations: {
        setGroupList(state, data) {
            if(!data) return
            state.groups = data
        },

        setSingleGroup(state, data) {
            if(!data) return
            state.single = data.data
        },

        setGroupInvites(state, data) {
            if(!data) {
                state.invites = null
                return
            }

            state.invites = data.data
        },

        setUserGroupTransfers(state, data) {

            if(!data) {
                state.userTransfers = []
                return
            }

            state.userTransfers = data.data
        },

        setGroupError(state, error) {
            if(!error) {
                state.errors = []
                return;
            }
            state.errors = []
            state.errors.push(error)
        }

    },

    getters: {
        getGroupList(state) {
            return state.groups
        },

        getSingleGroup(state) {
            return state.single
        },

        getGroupInvites(state) {
            return state.invites
        },

        getUserGroupTransfers(state) {
            return state.userTransfers
        },

        getGroupError(state) {
            return state.errors
        }
    }
}
