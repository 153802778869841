import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ThankYou.vue')
  },
  {
    path: '/last-orders',
    name: 'OrdersList',
    component: () => import(/* webpackChunkName: "Usert Card Info" */ '../views/OrdersList.vue'),
  },
  {
    path: '/sing-in',
    name: 'SingIn',
    component: () => import(/* webpackChunkName: "SingIn" */ '../views/SingIn.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassoword',
    component: () => import(/* webpackChunkName: "ForgotPassoword" */ '../views/ForgotPassoword.vue')
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "Registration" */ '../views/RegistrUser.vue')
  },
  {
    path: '/offers',
    name: 'Offers',
    component: () => import(/* webpackChunkName: "Offers" */ '../views/OffertsView.vue')
  },
  {
    path: '/political-confidence',
    name: 'Political',
    component: () => import(/* webpackChunkName: "Political" */ '../views/PoliticalConfidence.vue')
  },
  {
    path: '/bonuses',
    name: 'Bonuses',
    component: () => import(/* webpackChunkName: "PayBonusInfo" */ '../views/user/PayBonusInfo.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "Contacts" */ '../views/ContactsVIew.vue')
  },
  {
    path: '/account',
    children: [
      {
        path: '',
        name: 'UserAccount',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "UserAccount" */ '../views/user/UserAccount.vue'),
      },
      {
        path: 'setting',
        name: 'UserSetting',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "User Setting" */ '../views/user/UserSetting.vue'),
      },
      {
        path: 'my-cards',
        name: 'UserCards',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "User Cards" */ '../views/user/UserCards.vue'),
      },
      {
        path: 'my-payment-accounts',
        name: 'UsePaymentAccounts',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "User Cards" */ '../views/user/UserWashesAccount.vue'),
      },
      {
        path: 'card-info/:id/:name',
        name: 'UserCardInfo',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Usert Card Info" */ '../views/user/UserCardInfo.vue'),
      },
      {
        path: 'pay-history',
        name: 'UserPayHistory',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Usert Pay History" */ '../views/user/UserPayHistory.vue'),
      },
      {
        path: 'group-transfers',
        name: 'UserCardsTransfers',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Usert Card Transfers" */ '../views/user/UserCardsTransfers.vue'),
      },
      {
        path: 'invites-list',
        name: 'InvitesList',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "Usert Card Info" */ '../views/user/InvitesList.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('userToken')) {
      next({ name: 'SingIn' })
    } else {
      next() 
    }
  }else if(to.name == 'SingIn' && localStorage.getItem('userToken')) {
    next({ name: 'home' })
  } 
  else {
    next() 
  }

})
