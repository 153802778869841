<template>
  <div
    class="home"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg-image.svg') + ')',
    }"
  >
    <Preloader :show="preloaderVisible" />
    <div>
      <div class="row direct-column" v-if="getUser && getUser.amount < 20">
        <div class="upBalance">
          <input
            type="number"
            v-model="replenish"
            placeholder="Поповнити баланс"
          />
          <Transition name="fade-down">
            <button type="submit" v-if="replenish" @click="upBalance">
              поповнити баланс
            </button>
          </Transition>
        </div>
      </div>
      <div class="row direct-column">
        <div class="title">
          <p></p>
        </div>
        <div class="geolocation-wrap region">
          <p>
            <Multiselect
              v-model="selectedRegion"
              placeholder="Оберіть місто"
              :close-on-select="true"
              :groups="true"
              :searchable="true"
              :options="regionList"
              @click="selectRegion"
            />
            <button class="geolocation" type="button" @click.prevent="getLocation">
              <svg fill="#fff" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 297 297" xml:space="preserve">
<path d="M148.5,0C66.653,0,0.067,66.616,0.067,148.499C0.067,230.383,66.653,297,148.5,297s148.433-66.617,148.433-148.501  C296.933,66.616,230.347,0,148.5,0z M158.597,276.411v-61.274c0-5.575-4.521-10.097-10.097-10.097s-10.097,4.521-10.097,10.097  v61.274c-62.68-4.908-112.845-55.102-117.747-117.814h61.207c5.575,0,10.097-4.521,10.097-10.097s-4.522-10.097-10.097-10.097  H20.656C25.558,75.69,75.723,25.497,138.403,20.589v61.274c0,5.575,4.521,10.097,10.097,10.097s10.097-4.521,10.097-10.097V20.589  c62.681,4.908,112.846,55.102,117.747,117.814h-61.207c-5.575,0-10.097,4.521-10.097,10.097s4.521,10.097,10.097,10.097h61.207  C271.441,221.31,221.276,271.503,158.597,276.411z"/>
</svg>
            </button>
          </p>
        </div>
        <div class="geolocation-wrap">
          <Multiselect
            v-model="washValue"
            :options="washList"
            placeholder="Оберіть мийку"
            :searchable="true"
            label="name"
            @click="showWashBoxes"
          />
        </div>
      </div>
      <Transition name="fade-down">
        <div
          class="row direct-column services-wrap"
          v-if="washValue && postsOptions"
        >
          <div class="container-wrap">
            <div>
              <div class="title">
                <p>Оберіть послугу:</p>
              </div>
            </div>
            <div class="buttons-wrap">
              <button
                v-if="postsOptions"
                @click.prevent="
                  (selectServices = !selectServices), (postsValue = null)
                "
                :class="{ active: !selectServices }"
              >
                Мийка
              </button>
              <button
                v-if="vacuumOptions"
                @click.prevent="
                  (selectServices = !selectServices), (postsValue = null)
                "
                :class="{ active: selectServices }"
              >
                Порохотяг
              </button>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="fade-out">
        <div class="row direct-column" v-if="!selectServices && washValue">
          <div class="title">
            <p></p>
          </div>
          <div>
            <span class="icon"></span>
            <Multiselect
              v-model="postsValue"
              :options="postsOptions"
              ref="Posts"
              placeholder="Оберіть мийний пост"
              label="name"
              @click="addToquery"
            />
          </div>
        </div>
      </Transition>
      <Transition name="fade-out">
        <div class="row direct-column" v-if="selectServices && washValue">
          <div class="title">
            <p></p>
          </div>
          <div>
            <span class="icon"></span>
            <Multiselect
              v-model="postsValue"
              :options="vacuumOptions"
              placeholder="Оберіть Порохотяг"
              label="name"
              @click="addToquery"
            />
          </div>
        </div>
      </Transition>
      <Transition name="fade-down">
        <div class="wrapper" v-if="postsValue">
          <Transition name="fade-down">
            <div class="row direct-column" v-if="postsValue">
              <div>
                <span class="icon"></span>
                <div class="sum-type-wrap container-wrap">
                  <div class="sum-type-custom" v-if="!selectPaymentPrice">
                    <input
                      type="number"
                      name="sum"
                      placeholder="Введіть суму поповнення"
                      v-model="price"
                      @input="replenish = null"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade-down">
            <div class="row direct-column" v-if="postsValue">
              <div></div>
              <div class="price-buttons-wrap">
                <button
                  type="button"
                  class="brn btn-border"
                  value="20"
                  @click="selectPrice"
                >
                  20 грн
                </button>
                <button
                  type="button"
                  class="brn btn-border"
                  value="50"
                  @click="selectPrice"
                >
                  50 грн
                </button>
                <button
                  type="button"
                  class="brn btn-border"
                  value="100"
                  @click="selectPrice"
                >
                  100 грн
                </button>
                <button
                  type="button"
                  class="brn btn-border"
                  value="200"
                  @click="selectPrice"
                >
                  200 грн
                </button>
              </div>
            </div>
          </Transition>
        </div>
      </Transition>
      <Transition name="fade-down">
        <div
          class="row direct-column wrapper pay-accounts-wrap"
          v-if="getUser && washValue"
        >
          <h3>Мої рахунки</h3>
          <ul
            class="pay-accounts-list"
            v-if="payBalanceAccouns && payBalanceAccouns.length"
          >
            <li
              class="item-accout"
              v-for="item in payBalanceAccouns"
              :key="item"
            >
              <p class="item-accout-content">
                <span v-if="item.name">{{ item.name }}</span>
                <span v-if="!item.name">{{ item.washesGroup.name }}</span>
                <span v-if="item.amount">{{ item.amount }} монет</span>
                <button
                  v-if="
                    postsValue &&
                    price &&
                    !replenish &&
                    getUser &&
                    item.amount >= Number(price)
                  "
                  @click="balancePay(event, item.id)"
                  class="btn samll-btn"
                >
                  Оплатити
                </button>
                <router-link
                  :to="{ name: 'UsePaymentAccounts' }"
                  class="btn btn-white"
                  v-if="item.amount == 0 || item.amount < Number(price)"
                  >Поповнити</router-link
                >
              </p>
            </li>
          </ul>
          <div v-else class="row direct-column">
            <p>У вас немає доступних рахунків</p>
            <router-link
              :to="{ name: 'UsePaymentAccounts' }"
              class="btn btn-white"
              >Створити</router-link
            >
            <br />
          </div>
        </div>
      </Transition>
      <div class="form-footer">
        <Transition name="fade-out">
          <div
            v-if="postsValue && price && !replenish"
            class="row direct-column pay-button-wrap"
          >
            <button :disabled="!checked" @click="sendForm">
              Оплатити Картою
            </button>
            <div>
              <label class="checkbox-input">
                Я прочитав (а) і погоджуюся з
                <router-link
                  :to="{ name: 'Offers' }"
                  target="_blank"
                  class="red-link"
                  >умовами користування веб сайтом</router-link
                >
                *
                <input type="checkbox" checked="checked" v-model="checked" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </Transition>
        <!-- <Transition name="fade-out">
            <button v-if="postsValue && price && !replenish && getUser && hasMoney" @click="balancePay" >Оплатити з балансу</button>
         </Transition> -->

        <div v-if="getErrors.length > 0" class="errors-wrap">
          <span v-for="error in getErrors" :key="error.index">
            {{ error }}
          </span>
        </div>
        <div class="errors-wrap" v-if="error">
          <span>{{ error }}</span>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <div class="info-item">
        Бонусна програма
        <div>
          <p class="text">Отримай від 10% до суми поповнення</p>
          <router-link :to="{ name: 'Bonuses' }" class="btn samll-btn"
            >Детальніше</router-link
          >
        </div>
      </div>
    </div>
    <!-- <Transition name="fade-modal">
      <div v-if="showModal && !preloaderVisible" class="modal payment" @click.self="showModal = false">
          <div class="content">
            <button class="close" value="close" @click="showModal = false">X</button>
            <p v-if="getSelectedWash"><strong>Мийка:</strong><br> <span>{{getSelectedWash.address}}</span></p>
             <p v-if="getSelectedWash"><strong v-if="!selectServices">Пост:</strong> <strong v-if="selectServices">Пилосос:</strong><br> <span>{{getSelectedBox.name}}</span></p>

             <p v-if="price"><strong>Сума:</strong><br> <span>{{price}} монет</span></p>
             <form v-if="getErrors.length == 0 && getPaymendForm" method="POST" :action="getPaymendForm.url" accept-charset="utf-8" >
              <input type="hidden" name="data" :value="getPaymendForm.data" />
              <input type="hidden" name="signature" :value="getPaymendForm.signature" />
              <label class="checkbox-input">
                Я прочитав (а) і погоджуюся з <router-link :to="{name: 'Offers'}" target="_blank">умовами користування веб сайтом</router-link> *
                <input type="checkbox" checked="checked" v-model="checked">
                <span class="checkmark"></span>
              </label>
              <button :disabled="!checked">Оплатити</button>
            </form>
          </div>
      </div>
    </Transition> -->
    <br />
  </div>
</template>

<script>
// @ is an alias to /src
import Multiselect from "@vueform/multiselect";
import { mapGetters } from "vuex";
import Preloader from "@/components/PreLoader.vue";

export default {
  components: {
    Multiselect,
    Preloader,
  },
  data() {
    return {
      selectPaymentPrice: false,
      selectServices: false,
      showModal: false,
      washList: null,
      washValue: null,
      regionList: null,
      selectedRegion: null,
      placeOptions: [],
      postsValue: null,
      postsOptions: [],
      vacuumOptions: [],
      customSum: null,
      price: null,
      replenish: null,
      error: null,
      payData: {
        boxId: 0,
        amount: 0,
      },
      preloaderVisible: false,
      checked: true,
      hasMoney: false,
      currentBalanceCard: null,
      payBalanceAccouns: null,
    };
  },
  mounted() {
    this.loadWahses();
   // this.getLocation();
  },
  computed: {
    ...mapGetters([
      "getWashes",
      "getWashesAddress",
      "getWashBoxes",
      "getPaymendForm",
      "getErrors",
      "getPaymentsErrors",
      "getSelectedWash",
      "getSelectedBox",
      "getUser",
    ]),
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.success);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },

    success(pos) {
      const crd = pos.coords;
      // console.log("Your current position is:");
       console.log(`Latitude : ${crd.latitude}`);
       console.log(`Longitude: ${crd.longitude}`);
      // console.log(`More or less ${crd.accuracy} meters.`);

     this.findClosestWash(this.getWashes, crd.latitude, crd.longitude);
    },
    findClosestWash(elements, targetLat, targetLon) {
      if (!Array.isArray(elements) || elements.length === 0) {
            return null;
          }
          

      const defaultLat = targetLat;
      const defaultLon = targetLon;

        let closestElement = null


            function toRadians(degrees) {
            return degrees * (Math.PI / 180);
        }

        // Haversine formula to calculate distance between two points on the Earth
        function haversineDistance(lat1, lon1, lat2, lon2) {
            const R = 6371; // Radius of the Earth in kilometers
            const dLat = toRadians(lat2 - lat1);
            const dLon = toRadians(lon2 - lon1);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                      Math.sin(dLon / 2) * Math.sin(dLon / 2);
            
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c; // Distance in kilometers
        }

        // Find the closest location by calculating the smallest distance
        const closestLocation = elements.reduce((closest, current) => {
            const currentDistance = haversineDistance(defaultLat, defaultLon, parseFloat(current.lat), parseFloat(current.lon));
            const closestDistance = haversineDistance(defaultLat, defaultLon, parseFloat(closest.lat), parseFloat(closest.lon));
            
            return currentDistance < closestDistance ? current : closest;
        });
       
      this.washValue = closestLocation?.id;
      this.selectRegionByWash();
      this.selectRegion()
      this.addToquery();

      return closestElement;
    },
    loadWahses() {
      this.preloaderVisible = true;
      this.$store.dispatch("loadAllWashes").then(() => {
        this.getRegionList();
        this.preloaderVisible = false;
        this.washList = this.getWashesAddress;

        this.payBalanceAccouns = null;
        this.selectedParam();
        setTimeout(() => {
          this.isHasMoney();
        }, 300);
      });
    },

    getRegionList() {
      const regionsMap = new Map();

      this.getWashesAddress.forEach((item) => {
        if (!regionsMap.has(item.region)) {
          regionsMap.set(item.region, new Set());
        }
        regionsMap.get(item.region).add(item.city);
      });

      this.regionList = Array.from(regionsMap, ([region, citiesSet]) => ({
        label: region,
        options: Array.from(citiesSet),
      })).sort((a, b) => a.label.localeCompare(b.label));
    },
    selectRegion() {
      if (this.selectedRegion) {
        this.washList = this.getWashesAddress.filter(
          (wash) => wash.city == this.selectedRegion
        );
        this.washValue =
          this.washList.length > 1 && this.washList.find(item => item.id == this.washValue ) ? null : this.washList[0]["value"];
      } else {
        this.washList = this.getWashesAddress;
        this.washValue = this.washList.find(item => item.id == this.washValue ) ? this.washValue : null;
      }
    },
    getPostIdbyName(washName, type) {
      let post = type
        ? this.getWashBoxes.findIndex(
            (wash) => wash.name == washName && wash.type == type
          )
        : this.getWashBoxes.findIndex((wash) => wash.name == washName);

      if (type == "vacuum" && post == -1) {
        let name = washName.split(" ");
        post = this.getWashBoxes.findIndex((wash) => wash.value == name[0]);
      }

      return this.getWashBoxes[post].value;
    },

    getPostNamebyId(washId, type) {
      const post = this.getWashBoxes.findIndex((wash) => wash.value == washId);

      if (type == "number") {
        const name = this.getWashBoxes[post]["name"].split(" ");

        return name[0];
      }

      return this.getWashBoxes[post]["name"];
    },

    async loadwashBoxes(washId) {
      if (washId) {
        await this.$store.dispatch("loadWashPosts", washId).then(() => {
          if (this.getWashBoxes) {
            this.postsOptions = this.getWashBoxes.filter(
              (elem) => elem.type == "wash"
            );
            this.vacuumOptions = this.getWashBoxes.filter(
              (elem) => elem.type == "vacuum"
            );

            if (!this.postsOptions || this.postsOptions.length == 0) {
              this.postsOptions = null;
              this.selectServices = true;
            }

            if (!this.vacuumOptions || this.vacuumOptions.length == 0) {
              this.vacuumOptions = null;
              this.selectServices = false;
            }
          }
        });
      }
    },

    isHasMoney() {
      if (!this.getUser) return;
      const washGroupIds = this.getWashes
        .map((item) => {
          if (item.id == this.washValue) {
            return item.washGroupId;
          }
        })
        .filter((elem) => elem);

      const isMoney = this.getUser.loyaltyCard.length
        ? this.getUser.loyaltyCard.filter((item) => {
            if (
              item.washesGroup.id == washGroupIds[0] &&
              item.amount >= Number(this.price)
            ) {
              return item;
            }
          })
        : null;

      this.hasMoney = isMoney.length ? true : false;
      this.currentBalanceCard = isMoney.length ? isMoney[0].id : null;
      this.payBalanceAccouns = this.getUser.loyaltyCard.length
        ? this.getUser.loyaltyCard.filter((item) => {
            if (item.washesGroup.id == washGroupIds[0]) {
              return item;
            }
          })
        : null;
    },
    balancePay(event, bankAccountId) {
      let id = null;
      if (this.getUser) {
        id = Number(this.getUser.id);
      }

      this.preloaderVisible = true;

      this.$store
        .dispatch("payFromBalance", {
          wash: this.washValue,
          box: this.postsValue,
          amount: this.price,
          user_id: id,
          loyaltyCard: bankAccountId,
        })
        .then(() => {
          this.preloaderVisible = false;
          this.$store.dispatch("loadUser");
          if (this.getPaymentsErrors && this.getPaymentsErrors.length > 0) {
            this.$swal.fire({
              icon: "error",
              title: this.getPaymentsErrors,
              confirmButtonColor: "#fc2629",
            });

            return;
          }

          this.price = null;

          this.$swal.fire({
            icon: "success",
            title: "Оплата отримана",
            confirmButtonColor: "#fc2629",
          });

          this.$store.dispatch("loadUser").then(() => {
            this.isHasMoney();
          });
        });
    },
    payWash() {
      let id = null;
      if (this.getUser) {
        id = Number(this.getUser.id);
      }

      this.preloaderVisible = true;

      this.$store
        .dispatch("Pay", {
          washId: this.washValue,
          boxId: this.postsValue,
          amount: this.price,
          userId: id,
          type: "web",
        })
        .then(() => {
          this.preloaderVisible = false;

          this.redirectToLiqpayPayment(
            this.getPaymendForm.url,
            this.getPaymendForm.data,
            this.getPaymendForm.signature
          );
        });
    },

    addToquery() {
      const postType = !this.selectServices ? "post" : "vacuum";

      if (this.washValue && this.postsValue) {
        this.$router.replace({
          query: {
            geolocation: this.washValue,
            [postType]: this.getPostNamebyId(this.postsValue, "number"),
          },
        });
      } else if (!this.washValue) {
        this.postsValue = null;
        this.$router.replace({ query: {} });
        this.selectServices = false;
      } else if (this.postsValue) {
        this.$router.replace({
          query: {
            [postType]: this.getPostNamebyId(this.postsValue, "number"),
          },
        });
      } else if (this.washValue) {
        this.$router.replace({ query: { geolocation: this.washValue } });
      } else {
        this.$router.replace({ query: {} });
        this.selectServices = false;
      }
    },
    selectRegionByWash() {
      const washArray = this.getWashes.find(
        (wash) => wash.id == this.washValue
      );
      this.selectedRegion = washArray ? washArray.city : null;
    },

    showWashBoxes() {
      this.addToquery();
      if (this.washValue) {
        this.postsValue = null;
        this.selectRegionByWash();
        this.loadwashBoxes(this.washValue);
        this.isHasMoney();
      } else {
        this.postsValue = null;
        this.price = null;
        this.isHasMoney();
      }
    },

    async selectedParam() {
      if (!this.$route.query.geolocation) return;

      this.washValue = this.$route.query.geolocation;
      this.selectRegionByWash();

      await this.loadwashBoxes(this.washValue).then(() => {
        if (this.$route.query.post && this.getWashBoxes) {
          const postName = this.$route.query.post.split(" ");
          this.postsValue = this.getPostIdbyName(
            postName[1]
              ? this.$route.query.post
              : `${this.$route.query.post} пост`
          );
        }

        if (this.$route.query.vacuum && this.getWashBoxes) {
          this.selectServices = true;
          const vacumName = this.$route.query.vacuum.split(" ");
          this.postsValue = this.getPostIdbyName(
            vacumName[1]
              ? this.$route.query.vacuum
              : `${this.$route.query.vacuum} порохотяг`,
            "vacuum"
          );
        }

        this.isHasMoney();
      });
    },

    selectPrice(event) {
      this.price = event.target.value;
      this.replenish = null;
      this.isHasMoney();
    },
    upBalance() {
      if (this.replenish) {
        this.price = String(this.replenish);
        this.showModal = true;
        this.error = null;
        this.payBalance();
      }
    },
    payBalance() {
      this.$store.dispatch("Pay", {
        userId: this.getUser.id,
        amount: this.price,
        balance: true,
      });
    },
    sendForm() {
      if (this.postsValue && this.washValue) {
        if (this.price) {
          this.payWash();
          this.$store.dispatch("selectedWash", this.washValue);
          this.$store.dispatch("selectedBox", this.postsValue).then(() => {
            this.showModal = true;
          });

          this.error = null;
        } else {
          this.error = "Вкажить суму поповнення";
        }
      } else {
        this.error = "Виберіть мийку та послугу";
      }
    },
    redirectToLiqpayPayment(url, data, signature) {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action = url;

      // Create hidden input fields for data and signature
      const inputData = document.createElement("input");
      inputData.type = "hidden";
      inputData.name = "data";
      inputData.value = data;

      const inputSignature = document.createElement("input");
      inputSignature.type = "hidden";
      inputSignature.name = "signature";
      inputSignature.value = signature;

      // Append input fields to the form
      form.appendChild(inputData);
      form.appendChild(inputSignature);

      // Append the form to the document body
      document.body.appendChild(form);

      // Submit the form to redirect to Liqpay payment page
      form.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/home_page.scss";
</style>
