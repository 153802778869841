import { createStore } from "vuex";
import { user } from "./modules/user";
import { washes } from "./modules/washes";
import { liqpay } from "./modules/liqpay";
import { payment } from "./modules/payment";
import { loyaltyCards } from "./modules/loyaltyCards";
import { loyaltyGroups } from "./modules/loyaltyGroups";

export default createStore({
  modules: {
    user,
    washes,
    liqpay,
    payment,
    loyaltyCards,
    loyaltyGroups,
  },
});
