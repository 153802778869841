<template>
  <HeaderSite/>
  <div class="content"><router-view/></div>
  <FooterSite/>
</template>
<script>
  import HeaderSite from '@/components/HeaderSite.vue'
  import FooterSite from '@/components/FooterSite.vue'

export default {
  components: {
    HeaderSite,
    FooterSite
  }
}
</script>
<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700;900&display=swap');
  @import "src/assets/scss/style.scss";
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
