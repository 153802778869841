import router from '@/router'

import {
    API_CARDS, 
    API_USER_COUNT_CREATE,
    API_USER_COUNT_DELETE,
    API_ACTIVATE_CARD,
    API_VERIFY_CARD,
    API_DELETE_CARD
} from '../ApiUrl'

const token = () => {
    if(!localStorage.getItem('userToken')) {
        return null
    }

    let token;
    if(localStorage.getItem('userToken')) {
        token = localStorage.getItem('userToken').replaceAll('"', '')
    } 

    return token
}

export const loyaltyCards = {

    state: () => ({
        cards: [],
        errors: []
    }),
    
    actions: {
        async createUserLoyaltyCard({commit}, data) {
            if(!data) {
                return
            }
            
            const formData = new FormData()
            data.washesGroupId? formData.append("washesGroupId", data.washesGroupId) : '';
            data.groupLoyaltyCardId? formData.append("groupLoyaltyCardId", data.groupLoyaltyCardId) : '';
            data.loyaltyCard? formData.append("loyaltyCard", data.loyaltyCard) : '';
            data.name? formData.append("name", data.name) : '';


            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            } 

           try {

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+API_USER_COUNT_CREATE,
                requestOptions,
            )

            if(res.status != 204) {
                const cards = await res.json()
                commit('userAccount', cards)
            }
            
           } catch (error) {
                console.error(error)
           }

        },

        async deleteLoadCards({commit}, id) {
            if(!id) return

            const formData = new URLSearchParams()
            formData.append("id", id);

            const requestOptions = {
                method: 'DELETE',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: formData
            }
            
            try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN+API_USER_COUNT_DELETE,
                    requestOptions,
                )

                if(res.status != 204) {
                    const cards = await res.json()
                    commit('userAccount', cards)
                }
            
            } catch (error) {
                console.error(error)
            }
    

        },

       async loadCards({commit}) {
            if(!token) {
                return
            }

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
              }

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+API_CARDS,
                requestOptions
                )

                const cards = await res.json()

                commit('setLoyaltyCars', cards)
       },

       async createCard({dispatch}, data) {

        if(!token || !data) {
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Accept' : 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token() 
            },
            body: JSON.stringify(data.card[0])
          };

          await fetch(process.env.VUE_APP_API_DOMAIN + API_CARDS +'/'+data.userId, requestOptions)
            .then(async response => {
                
              const data = await response.json();
        
              if (!response.ok) {

                const error = (data && data.message) || response.status;
                return Promise.reject(error);

              }

              dispatch('loadCards')

            })
            .catch(error => {

              this.errorMessage = error;
            });
       },

       async activateCard({commit, dispatch}, cardId){

        if(!token || !cardId) {
            return
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Accept' : 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token() 
            }
          };

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_ACTIVATE_CARD + cardId,
                requestOptions
            )
            
            if(res.status == 401) {
                router.push({name: 'home'})
                return
            }
            
            if(res.status != 204) {
                const cards = await res.json()

                commit('setErrors', cards)

                return
            }

            dispatch('loadCards')
       },


       async verifyCard({commit, dispatch}, cardId){

        if(!token || !cardId) {
            return
        }

        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Accept' : 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token() 
            }
          };

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_VERIFY_CARD + cardId,
                requestOptions
            )
            
            if(res.status == 401) {
                router.push({name: 'home'})
                return
            }
            
            if(res.status != 204) {
                const cards = await res.json()

                commit('setErrors', cards)

                return
            }

            dispatch('loadCards')
       },

       async deleteCard({commit, dispatch}, cardId){

        if(!token || !cardId) {
            return
        }

        const requestOptions = {
            method: 'DELETE',
            headers: { 
                'Accept' : 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token() 
            }
          };

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_DELETE_CARD + cardId,
                requestOptions
            )
            
            if(res.status == 401) {
                router.push({name: 'home'})
                return
            }
            
            if(res.status != 204) {
                const cards = await res.json()

                commit('setErrors', cards)
                return
            }

            dispatch('loadCards')
            .then(()=> {
                router.push({name: "UserCards"})
            })
       }

        
       
    },
    mutations: {

        setUserAccount(state, card) {
            if(!card) return

            state.card = card.data

        },
        
        setLoyaltyCars(state, cards) {
            if(!cards) {
                return
            }
            state.cards = []
            
            state.cards = cards.data
        },
        

        setErrors(state, error) {
            if(!error) {
                state.errors = []
                return;
            }
            state.errors = []
            state.errors.push(error)
        }
    },

    getters: {
        getLoyaltyCars( state ) {
            return state.cards
        },
        getCardsErrors( state ) {
            return state.errors
        }
    },
}