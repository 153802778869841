

import {
    API_USER_REPLENISHMENT,
    API_USER_TRANSACTION,
    API_USER_PAY_OF_BALANCE,
    API_USER_TRANSFER_TO_USER
} from '../ApiUrl'

const token = () => {
    if(!localStorage.getItem('userToken')) {
        return null
    }

    let token;
    if(localStorage.getItem('userToken')) {
        token = localStorage.getItem('userToken').replaceAll('"', '')
    } 

    return token
}

export const payment = {

    state: () => ({
        payData: null,
        order: null,
        is_paid: null,
        paymentToken: null,
        paymentId: null,
        status: null,
        historyList: [],
        check: null,
        errors: []
    }),
    
    actions: {

        async loadUserReplenishment({commit}, orderId) {
            if(!token()) {
                return
            } 

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
              }

              
              let id = ''
              if(orderId) {
                id = `/${orderId}`
              }

              const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_USER_REPLENISHMENT + id,
                requestOptions
                )

                const replenishment = await res.json()

                commit('setHistory', replenishment)

        },


        async loadUserPayments({commit}, orderId) {
            if(!token()) {
                return
            } 

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
              }

              let id = ''
              if(orderId) {
                id = `/${orderId}`
              }


              try {

                const res = await fetch(
                    process.env.VUE_APP_API_DOMAIN + API_USER_TRANSACTION + id,
                    requestOptions
                )

                const payments = await res.json()

                commit('setHistory', payments)
                
              } catch (error) {
                console.error(error)
              }

        },

        

        async payFromBalance({commit}, data) {
            if(!token()) {
                return
            } 

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: JSON.stringify(data)
              }

              const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_USER_PAY_OF_BALANCE,
                requestOptions
                )

                const pay = await res

                if(res.status !== 204) {
                    commit('setPaymentsErrors', pay.message)
                    return;
                }
        },

        async loadCheckPayment(__, orderData) {
            if(!token()) {
                return
            } 

            if(!orderData.orderId) {
                return
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderData),
              };
              

            try {
                const response = await fetch(
                  process.env.VUE_APP_API_DOMAIN + '/api/get-pdf/' + orderData.orderId,
                  requestOptions
                );
              
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
              
                const blob = await response.blob();
              
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'document.pdf';
              
                document.body.appendChild(link);
                link.click();
              
                document.body.removeChild(link);
              
              } catch (error) {
                console.error('Error downloading PDF:', error);
              }
                
        },
        async replenishBalanceChildUser({commit}, data) {
            if(!token() || !data) {
                return
            } 

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
                body: JSON.stringify(data)
              }

              const res = await fetch(
                process.env.VUE_APP_API_DOMAIN + API_USER_TRANSFER_TO_USER,
                requestOptions
                )

                const pay = await res

                if(res.status !== 204) {
                    commit('setPaymentsErrors', pay.message)
                    return;
                }
        }
       
    },
    mutations: {

        setPaymentsErrors(state, error) {
            if(!error) {
                state.errors = []
                return;
            }
            state.errors = []
            state.errors.push(error)
        },

        setHistory(state, history) {
            if(!history) {
                state.historyList = []
            }

            state.historyList = history   
        },

        setCheckPayment(state, check) {
            if(!check) {
                state.check = null
            }
            
            state.check = check.data
        },
    },

    getters: {
        getPaymentsErrors( state ) {
            return state.errors
        },
        getPayHistory( state ) {
            return state.historyList
        },
        getCheckPayment( state ) {
            return state.check
        }
    },
}