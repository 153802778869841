import router from '@/router'

import {
    API_BONUS
} from '../ApiUrl'


const token = () => {
    if(!localStorage.getItem('userToken')) {
        return null
    }

    let token;
    if(localStorage.getItem('userToken')) {
        token = localStorage.getItem('userToken').replaceAll('"', '')
    } 

    return token
}

export const user = {
    state: () => ({
        user: null,
        userToken: null,
        userId: null,
        countriesCodes: [],
        userInfo: null,
        transaction: [],
        bonus: null,
        errors: []
    }),
    actions: {

        async userRegistration({commit, dispatch}, userData) {

            const dataUser = {
                'codeId': userData.codeId,
                'phone': userData.phone, 
                'code': userData.code,
                'password': userData.password,
                'password_confirmation': userData.password_confirmation,
                'deviceName': userData.deviceName
            }

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(dataUser)
              };

              const res = await fetch(process.env.VUE_APP_API_DOMAIN+'/api/auth/register', requestOptions)
               
              const data = await res.json();

            if(res.status == 422) {
                commit('setErrors', ['Код не знайдено'])
                return
            }

            if(res.status == 409) {
                commit('setErrors', ['Такий номер вже зареєстрований'])
                return
            }


            if(res.status != 200) {
                commit('setErrors', data.message)
                return
            }
                    
           


            commit('setUser', data)
            commit('setErrors', '')

            dispatch('logInUser', userData)

                
        },


        async verifySms({commit}, userData) {
           

            const data = userData

            if(!data) return

            let url = '/api/auth/send-verify-phone-sms'

            const phone = '380' + data['phone']

            if(userData.reset) {
                url = '/api/auth/send-reset-password-sms'
            }


            if(!userData) return

            const formData = new URLSearchParams()
            formData.append("phone", phone);

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData
              };

              const res = await fetch(process.env.VUE_APP_API_DOMAIN+url, requestOptions)

              if(res.status != 204) {
                commit('setErrors', 'Виникла помилка, спробуйте пізніше')
                return
              }
              
        },

        async logInUser({commit, dispatch}, userData) {

            if(!userData) return

            const formData = new URLSearchParams()
            userData.codeId ? formData.append("codeId", Number(userData.codeId)) : '';
            userData.phone ? formData.append("phone", userData.phone) : '';
            userData.password ? formData.append("password", userData.password) : '';
            userData.deviceName ? formData.append("deviceName", userData.deviceName) : '';

            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/x-www-form-urlencoded",
                    'Accept' : 'application/json',
                },
                body: formData
              };

            const res = await fetch(process.env.VUE_APP_API_DOMAIN+'/api/auth/login', requestOptions)
               
            

            if(res.status == 422) {
                commit('setErrors', 'Невірно введені телефон або пароль')
                return
            }

            if(res.status != 200) {
                commit('setErrors', res.message)
                return
            }

            const user = await res.json()
    
            commit('setUser', user)
            commit('setErrors', '')
            
            dispatch('loadUser')
            .then(()=> router.push({name: 'home'}))

        },

        async logOutUser({commit}, data) {
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type' : 'application/json',
                    'Authorization': 'Bearer '+ data.userToken.replaceAll('"', '') 
                },
                body: JSON.stringify({tokenId: data.userId})
              };

            await fetch(process.env.VUE_APP_API_DOMAIN+'/api/auth/logout', requestOptions)
            .then(async response => {
                if (response.status == 204) {

                    commit('setUser')
                    commit('setUserInfo')

                    localStorage.removeItem('userToken')
                    localStorage.removeItem('userId')

                }

              })
            
            
        },

        async loadCountriesCodes({commit}) {

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+'/api/auth/country-codes'
                )
            const codes = await res.json()

            commit('setCountriesCodes', codes)

        },

        async loadUser({commit}) {

            if(!token()) {
                return
            } 

            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                    'Authorization': 'Bearer ' + token()
                },
              }

            const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+'/api/users/me',
                requestOptions
                )

                if(res.status == 401) {
                    commit('setUser')
                    commit('setUserInfo')

                    localStorage.removeItem('userToken')
                    localStorage.removeItem('userId')

                    router.push({name: 'home'})

                    return
                }
                const userInfo = await res.json()

                commit('setUserInfo', userInfo)
                commit('setErrors', null)
        },

        async loadUserBonus({commit}) {
          
            const requestOptions = {
                method: 'GET',
                headers: { 
                    'Accept' : 'application/json',
                },
              }

              const res = await fetch(
                process.env.VUE_APP_API_DOMAIN+API_BONUS,
                requestOptions
                )

                const bonus = await res.json()

                commit('setUserBonus', bonus.data)

        },

        async updateUser({commit, dispatch}, userData) {


            if(!token()) {
                return
            } 

            const requestOptions = {
                method: 'PUT',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token() 
                },
                body: JSON.stringify(userData)
              };

              await fetch(process.env.VUE_APP_API_DOMAIN+'/api/users/my-update', requestOptions)
                .then(async response => {
                    
                  
            
                  if (!response.ok) {
                    const data = await response.json();
                    const error = (data && data.message) || response.status;
                    commit('setErrors', error)
                    return Promise.reject(error);
                   
                  }
                  
                  commit('setErrors', '')
                  dispatch('loadUser')
                  
                })
                .catch(message => {

                  this.errorMessage = message;
                  commit('setErrors', message)

                });
        },

        async forgotPassword({commit}, userdata){

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userdata)
              };
            
              await fetch(process.env.VUE_APP_API_DOMAIN+'/api/auth/reset-by-sms-code', requestOptions)
              .then(async response => {

                if(response.status == 404) {
                    commit('setErrors', 'Код скидання паролю не співпадає.')
                    return
                }

                if (!response.ok) {
                    const data = await response.json();
                    const error = (data && data.message) || response.status;
                    commit('setErrors', error)
                    return
                }

                commit('setErrors', '')

              })
        },

        async sendSmsSubUser({commit}, userData) {  
            
                if(!token()) {
                    return
                } 

                const data = userData
    
                const url = '/api/users/check-parent-id'
    
                const phone = '380' + data['phone']
    
    
                const requestOptions = {
                    method: 'POST',
                    headers: { 
                        'Accept' : 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+ token()
                    },
                    body: JSON.stringify({
                        'phone': phone,
                    })
                  };
    
                  const res = await fetch(process.env.VUE_APP_API_DOMAIN+url, requestOptions)

                  if(res.status == 404) {
                    commit('setErrors', 'Такого користувача не знайдено')
                    return
                  }

                  if(res.status == 409) {
                    commit('setErrors', 'Користувач вже приєднаний до групи')
                        return
                  }

                  if(res.status != 200) {
                    commit('setErrors', 'Виникла помилка, спробуйте пізніше')
                    return
                  }
                                   
                  commit('setErrors', '')
        },

        async addSubUser({commit, dispatch}, data) {
            if(!token()) {
                return
            } 
            
            data ? data['phone'] = '380' + data['phone'] : null

            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ token()
                },
                body: JSON.stringify(data)
              };

              await fetch(process.env.VUE_APP_API_DOMAIN+'/api/users/add-parent-to-user', requestOptions)
              .then(async response => {

                if (!response.ok) {
                    const data = await response.json();
                    const error = (data && data.message) || response.status;
                    commit('setErrors', error)
                    return 
                }

                commit('setErrors', '')
                dispatch('loadUser')
              })
        }

    },
    mutations: {
        setUser(state, user){
           if(!user) {
                state.userToken = null
                state.userId = null
                return
            }
            
            state.userToken = user.data.token
            state.userId = user.data.id

            localStorage.setItem('userToken', JSON.stringify(user.data.token))
            localStorage.setItem('userId', JSON.stringify(state.userId))
            
            
        },

        setUserTransaction(state, transaction) {
            if(!transaction) {
                state.transaction = []
            }
            
            state.transaction = transaction.data
        },

        setCountriesCodes(state, codes) {
            if(codes) {
                state.countriesCodes = []

                codes.data.every(code => {
                    let codes = {
                        value: code.id,
                        name: code.code
                    }
                    return state.countriesCodes.push(codes);
                })
            }
        },

        setUserInfo(state, userInfo) {
            if(!userInfo) {
                state.user = null
                return
            }

            state.user = userInfo.data
            
        },

        setUserBonus(state, bonus) {
            if(!bonus) return

            state.bonus = bonus

        },

        setErrors(state, error) {
            if(!error) {
                state.errors = []
                return;
            }
            state.errors = []
            state.errors.push(error)
        }
    },
    getters: {
        getUser(state){
            return state.user
        },
        getCountriesCodes(state) {
            return state.countriesCodes
        },
        getUserBonus(state) {
            return state.bonus
        },
        getUserErrors(state) {
            return state.errors
        }
    },
}