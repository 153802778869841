<template>
    <header>
        <div class="header-wrap">
            <div class="logo">
                <a href="/">
                    <img src="../assets/images/logo.png" alt="">
                </a>
            </div>
            <div class="balance" v-if="getUser">
                <router-link :to="{name: 'UsePaymentAccounts'}" class="btn samll-btn btn-white">Мої Рахунки</router-link>
                <!-- <router-link :to="{name: 'UserAccount'}" class="balance-text"> <strong>{{getUser.phone}}</strong> </router-link> -->
                <router-link :to="{name: 'UserAccount'}" class="acount-link">
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </router-link>
            </div>
            <div class="buttons-wrap" v-if="!getUser">
                <router-link :to="{name: 'SingIn'}" class="btn" v-if="!getUser">Увійти</router-link>
                <router-link :to="{name:'Registration'}" class="btn" v-if="!getUser">Зареєструватись</router-link>
                <!-- <p class="user-name" v-if="getUser"><router-link :to="{name: 'UserAccount'}" class="btn">{{getUser.phone}}</router-link></p> -->
                <!-- <button v-if="getUser" @click.prevent="logOut">Вийти</button> -->
            </div>
            <VerticalMenu/>
            <BannerPWA/>
        </div>
    </header>
</template>
<script>
import VerticalMenu from '@/components/VerticalNav.vue'
import BannerPWA from '@/components/BannerPWA.vue'

export default {
    components: {
        VerticalMenu,
        BannerPWA
    },
    created() {
        if(localStorage.getItem('userToken') && !this.getUser){
            this.$store.dispatch('loadUser')
        }
        
    },
    beforeUpdate(){
        if(localStorage.getItem('userToken') && !this.getUser){
            this.$store.dispatch('loadUser')
        }
    },
    computed:{
        getUser(){
            return this.$store.getters.getUser;
        },
    },
}
</script>
<style lang="">
    
</style>