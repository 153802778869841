<template>
    <footer>
        <div class="footer-wrap">

            <Transition name="user-menu">
                <UserMenu v-if="getUser" />
            </Transition>
            
        </div>
    </footer>
</template>
<script>
import UserMenu from '@/components/UserMenu.vue'

export default {
    components: {
        UserMenu,
    },
     computed: {
        getUser(){
            return this.$store.getters.getUser;
        }
    },
}
</script>
<style lang="">
    
</style>